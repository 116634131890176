import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomePageState, MeasurementPageState, PageKey, ResultPageState, ResultTabKey } from "./pageState";

interface PageManagerState {
    currentPage: PageKey;
    homeState: HomePageState;
    measurementState: MeasurementPageState;
    resultState: ResultPageState;
}

const initialState: PageManagerState = {
    currentPage: PageKey.Home,
    homeState: {},
    measurementState: {},
    resultState: {
        currentTab: ResultTabKey.CurrentMesasurement,
    },
}

export const pageManagerSlice = createSlice({
    name: 'pageManager',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<PageKey>) => {
            state.currentPage = action.payload;
        },
        updateResultPageTab: (state, action: PayloadAction<ResultTabKey>) => {
            state.resultState = {
                currentTab: action.payload,
            }
        }
    }
});

export const { updatePage, updateResultPageTab } = pageManagerSlice.actions;

export default pageManagerSlice;
import { NQRecord } from '../history/types';
import { getCurrentLang } from '../../helper/utils';
import { LangName } from '../../types';
import { PartialServiceState, getInputFromNqRecord, getPartialServiceState } from '../partialService/partialServiceUtils';
import { Models, NQHint } from '@visonum/network-quality-sdk';
import { hintIdsToHint3Objects, serverHintsToClientHintIds } from '../hints/hintFunctions';
import { createPrintRequestModel } from './createPrintRequestModel';
import { lastValueFrom } from 'rxjs';
import { getSdk } from '../../networkQuality';
import { logger } from '../../helper/logger';
import inlineMarkdownRender from '../../helper/inlineMarkdownRender';

export const printRequest3 = async (record: NQRecord): Promise<Blob> => {
    const lang = getCurrentLang();
    const tips = getTips(lang, getPartialServiceState(getInputFromNqRecord(record)), record);

    const printRequestModel = createPrintRequestModel(record, lang as Models.LanguageKey, tips);

    try {
        return await lastValueFrom(getSdk().print(printRequestModel));
    } catch (err) {
        logger.error("printRequest", err);
        throw err;
    }
}

const getTips = (lang: LangName, partialServiceState: PartialServiceState, record: NQRecord): Models.ReportModelTip[] | null => {
    if (record.hints === null) {
        return null;
    }

    const nqHints = serverHintsToClientHintIds(record.hints.map(id => ({ id } as NQHint)), partialServiceState);
    return hintIdsToHint3Objects(nqHints, record, lang)
        .map(h => ({
            version: Models.ReportModelTipVersionEnum.V3,
            id: h.id,
            title: h.quickTipTitle, // quik_tip_title
            teaserTitle: "",
            teaser: h.expandedTipBody, // expanded_tip_copy
            contentTitle: h.expandedTipSubline, // expanded_tip_subline
            content: inlineMarkdownRender(h.expandedTipSublineBody), // expanded_tip_subline_copy
        }));
}